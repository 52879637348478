import React, { useState, useEffect } from "react";
import { Accordion, Icon } from "semantic-ui-react";

function SidebarXauth({ tableContents, StepToBeHighlighted, docToFindHighlight }) {
  const [clickedToc, setClickedToc] = useState();

  function jaccardSimilarity(str1, str2) {
    const set1 = new Set(str1.split(' '));
    const set2 = new Set(str2.split(' '));

    const intersection = new Set([...set1].filter(word => set2.has(word)));
    const union = new Set([...set1, ...set2]);

    const similarity = intersection.size / union.size;
    return similarity;
}

  let highestMatchingStepId = '';
  let highestSimilarity = 0;
  let summaryToHighlight = docToFindHighlight.summary.replace(/<[^>]*>/g, '');
  if (docToFindHighlight.steps && docToFindHighlight.steps.length) {
    let procedureToCompare = `${docToFindHighlight.procedureTitle[0]} ${docToFindHighlight.procedureDescription[0]}`;
    procedureToCompare = procedureToCompare.replace(/<[^>]*>/g, '');
    let procedureSimilarity = jaccardSimilarity(summaryToHighlight, procedureToCompare);
    for (let i=0; i<docToFindHighlight.steps.length; i++) {
      let stepToCompare = `${docToFindHighlight.steps[i].stepTitle[0]} ${docToFindHighlight.steps[i].stepDescription[0]}`;
      stepToCompare = stepToCompare.replace(/<[^>]*>/g, '');
      let similarity = jaccardSimilarity(summaryToHighlight, stepToCompare);
      if (similarity > highestSimilarity) {
        highestSimilarity = similarity;
        highestMatchingStepId = docToFindHighlight.steps[i].stepId[0];
      }
    }
    if (procedureSimilarity > highestSimilarity) {
      highestMatchingStepId = '';
    }
  }
  useEffect(() => {
    if (highestMatchingStepId !== '') {
      updateHash(highestMatchingStepId);
    }
  }, []);
  const updateHash = (id) => {
    StepToBeHighlighted(id);
    setClickedToc(id);
    const element = document.getElementById(id);
    if (element) {
      // 👇 Will scroll smoothly to the top of the next section
      element.scrollIntoView({
        behavior: "auto",
      });
    }
  };
  return (
    <div className="sidebar">
      <Accordion>
        <Accordion.Content active={true}>
          <ul className="sidebar-ul">
            {tableContents.map((steps, index) => {
              return (
                <div>
                  {true && (
                    <div
                      className="tableOfContentsauth"
                      style={{
                        backgroundColor:
                          clickedToc === steps.stepId ? "#EEF5FC" : "",
                      }}
                      onClick={() => {
                        updateHash(steps.stepId);
                      }}
                    >
                      <div className="sidebarContent">
                        <li key={index} className="sidebar__highlightauth">
                          <div>
                            <blockquote
                              className="sidebar-text"
                              style={{
                                color:
                                  clickedToc === steps.stepId ? "#034E91" : "",
                              }}
                            >
                              {steps.stepTitle}
                            </blockquote>
                            <br />
                          </div>
                        </li>
                      </div>

                      <div className="sidebarContent">
                        <Icon
                          name={"chevron right"}
                          className="arrow-icon-auth"
                          style={{
                            color: clickedToc === steps.stepId ? "#034E91" : "",
                          }}
                        />
                      </div>
                    </div>
                  )}
                </div>
              );
            })}
          </ul>
        </Accordion.Content>
      </Accordion>
    </div>
  );
}

export default SidebarXauth;
