import React from "react";
import lex_logo from "../../assets/images/logo_lexx.png";
import { useNavigate } from "react-router";
import "../../assets/index.css";

const Logo = (props) => {
  const navigate = new useNavigate();
  const onclicklogo = () => {
    if (!props?.chatgpt) {
      navigate("/");
    }
  };

  return (
    <button
      className={props?.chatgpt ? "defaultbutton" : ""}
      onClick={onclicklogo}
    >
      <img className="w-9 md:w-auto" src={lex_logo} alt="lex_Logo" />
    </button>
  );
};

export default Logo;
