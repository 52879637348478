import React, { useEffect, useState } from "react";
import axios from "axios";
import search from "../../assets/images/search.svg";
import { connect } from "react-redux";
import {
  saveSearchResult,
  saveSearchWord,
  saveSearchResultAuthDocs,
  setEquipment,
  setTypes,
  setTotalDoc,
  setActivePage,
  addfilter,
  saveQAContent,
  setToaster,
} from "../../redux/actions";
import { Loader } from "semantic-ui-react";
import KeycloakUserService from "../../keycloak/KeycloakUserService.js";
import { useNavigate } from "react-router";
import { searchApi } from "../apiCall";
import vannord_logo from "../../assets/images/vanoord.png";
const Search = (props) => {
  const [query, setQuery] = useState(""); // Store the query
  const [loading, setLoading] = useState(false);
  const [noResult, setNoResult] = useState(false);
  const navigate = useNavigate();
  useEffect(() => {
    props.setActivePage(1);
    //  props.setTotalDoc(0);
  }, []);

  const onChange = (e) => {
    setNoResult(false);
    setQuery(e.currentTarget.value);
  };

  function handleSessionOut() {
    if (KeycloakUserService.isTokenExpired()) {
      navigate("/");
      localStorage.clear();
      props.saveSearchWord("");
      window.location.reload(false);
      KeycloakUserService.doLogout();
    }
  }

  const getDocs = async (e) => {
    // navigate("/searchResult");
    props.saveSearchWord(query);
    props.saveQAContent("");
    let filterData = [];
    props.addfilter(JSON.stringify({}));
    if (query !== "") {
      props.saveSearchWord(query);
      handleSessionOut();
      e.preventDefault();
      setLoading(true);
      var queryToExecute = {
        q: query,
        rows: 10,
        start: 0,
        filter: filterData,
      };

      await searchApi(queryToExecute)
        .then((res) => {
          if (res.data.response.numFound === 0) {
            setLoading(false);
            setNoResult(true);
          } else {
            props.saveSearchResult(JSON.stringify(res.data.response.docs));
            res.data.response.authDocs.length > 0
              ? props.saveSearchResultAuthDocs(
                  JSON.stringify(res.data.response.authDocs)
                )
              : props.saveSearchResultAuthDocs("");
            props.setTotalDoc(JSON.stringify(res.data.response.numFound));

            if (res.data.equipment !== undefined) {
              props.setEquipment(res.data.equipment);
            }
            if (res.data.type !== undefined) {
              props.setTypes(res.data.type);
            }

            navigate("/searchResult");
          }
        })
        .catch((error) => {
          props.setToaster(true);
          if (
            error.response.data === "Access denied" ||
            // eslint-disable-next-line no-magic-numbers
            error.response.status === 403
          ) {
            KeycloakUserService.doLogout();
          }
        });
    }
  };

  return (
    <>
      <div className="flex flex-col justify-center content-center items-center pb-20 mt-[-1rem]">
        <div className="font-bold text-[20px] md:text-[42px] leading-[22px] md:leading-[72px] text-[#0971CE] flex flex-col items-center font-inter">
          <div>
            {/* <img
              className="h-10 mr-3"
              src={vannord_logo}
              alt="vanoord_marine_ingeunity logo"
            /> */}
          </div>
          <div>xIntelisearch</div>{" "}
        </div>
        <form className={`relative  `}>
          <div className="mb-5 ml-[0.2rem] md:ml-o">
            <span className="material-symbols-outlined relative top-[0.6rem] md:top-2 left-7 md:left-10 z-10 ">
              <img
                className="w-3 md:w-5 mb-2.5 material-symbols-outlined "
                src={search}
                alt="search logo"
              ></img>
            </span>
            <input
              className={`w-[350px] md:w-[564px]  h-[22px]  md:h-[44px] text-[5] pl-10 md:pl-14 focus:outline-none rounded-[28px] border shadow-[0px_0px_5px_0px_rgba(0,0,0,0.30)] placeholder-gray-400 text-[7px] md:text-[14px]`}
              name="input"
              value={query}
              onChange={onChange}
              type="text"
              placeholder="Ask me anything?"
              title="Search"
            />{" "}
            <span className="material-symbols-outlined relative top-2 right-10"></span>
          </div>

          <div className="searchbutton">
            <button
              type="submit"
              className="bg-[#0971CE] h-[20px] md:h-[40px] w-[60px] md:w-[120px] text-[7px] md:text-[14px] rounded-[20px] text-white font-inter"
              onClick={(e) => getDocs(e)}
            >
              Search
            </button>
          </div>
        </form>
        <div style={{ paddingTop: "5px" }}>
          {loading ? (
            <Loader active inline />
          ) : (
            <div className={`${noResult ? "" : "dummy-space"}`}></div>
          )}
        </div>
        {noResult && (
          <div
            style={{
              paddingTop: "5px",
              color: "gray",
              height: " 2.28571429rem",
            }}
          >
            <p>No Search Result Found</p>{" "}
          </div>
        )}
      </div>
    </>
  );
};
const mapStateToProps = (state) => {
  return {
    searchedWord: state.searchedWord,
    pinnedSearches: state.pinnedSearches,
  };
};

export default connect(mapStateToProps, {
  saveSearchResult,
  saveSearchWord,
  saveSearchResultAuthDocs,
  setEquipment,
  setTypes,
  setTotalDoc,
  setActivePage,
  addfilter,
  saveQAContent,
  setToaster,
})(Search);
